/* label {
  display: flex;
  flex-direction: column;
  width: 30px;
  cursor: pointer;
  align-items: flex-end;
}

label span {
  background: #235dab;
  border-radius: 10px;
  height: 3px;
  margin: 3px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

label span:nth-of-type(1) {
  width: 50%;
}

label span:nth-of-type(2) {
  opacity: 0;
  width: 100%;
}

label span:nth-of-type(3) {
  width: 75%;
}

input[type="checkbox"] {
  display: none;
}

.active span {
  margin: 0;
}
.active span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(-45deg) translate(2px, -1px);
}

.active span:nth-of-type(2) {
  transform-origin: bottom;
  transform: rotatez(45deg);
}

.active span:nth-of-type(3) {
  width: 50%;
  transform-origin: bottom;
  transform: translate(-10px, 2.5px) rotatez(-45deg);
} */

.burgerBtn{
  display:flex;
  flex-direction:column;
  width:30px;
  cursor:pointer;
  transform: rotate(180deg)
}

#check{
  display: none;
}

.burgerBtn span{
  background: #235dab;
  border-radius:10px;
  height:3px;
  margin: 3px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);

}


.burgerBtn span:nth-of-type(1){
  width:50%;
  
}

.burgerBtn span:nth-of-type(2){
  width:100%;
}


.burgerBtn span:nth-of-type(3){
  width:75%;
 
}




.burgerBtn input[type="checkbox"]:checked ~ span:nth-of-type(1){
  transform-origin:bottom;
  transform:rotatez(45deg) translate(3.428px,0px)
}


.burgerBtn input[type="checkbox"]:checked ~ span:nth-of-type(2){
  
  transform-origin:top;
  transform:rotatez(-45deg)
}


.burgerBtn input[type="checkbox"]:checked ~ span:nth-of-type(3){
  
  transform-origin:bottom;
  width:50%;
  transform: translate(12.857px,-4.714px) rotatez(45deg);

}

.nav::-webkit-scrollbar{
  display: none;
}