@font-face {
	font-family: "SofiaPro";
	src: url("../public/font/SofiaProBlack.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SofiaPro";
	src: url("../public/font/SofiaProBold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SofiaPro";
	src: url("../public/font/SofiaProExtraLight.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "SofiaPro";
	src: url("../public/font/SofiaProLight.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "SofiaPro";
	src: url("../public/font/SofiaProMedium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "SofiaPro";
	src: url("../public/font/SofiaProRegular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-family: "SofiaPro";
}

ul,
ol {
	list-style-position: outside;
}
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	overflow-wrap: break-word;
	user-select: none;
	-webkit-user-select: none;
}

ul,
ol,
li,
span,
p {
	color: #464646;
}

.drop {
	display: grid;
	grid-template-rows: 0fr;
	transition: grid-template-rows 500ms;
}

.drop.active {
	grid-template-rows: 1fr;
}

.drop > div {
	overflow: hidden;
}

body {
	display: flex;
	justify-content: center;
	min-height: 100vh;
	background-color: #f5f5f5;
}

#root {
	max-width: 1920px;
	width: 100%;
}

body.modal-open {
	overflow: hidden;
}
.footer-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.footer-grid-col:first-child {
	grid-column: span 2;
}

.footer-grid-col:last-child {
	grid-column: span 2;
}

.react-datepicker__input-container {
	@apply max-w-[290px];
}

react-datepicker__triangle::before,
react-datepicker__triangle::after {
	visibility: hidden !important;
}

@media only screen and (min-width: 1025px) {
	.footer-grid {
		grid-template-columns: repeat(4, 1fr);
	}

	.footer-grid-col:first-child {
		grid-column: auto;
	}

	.footer-grid-col:last-child {
		grid-column: auto;
	}
}
.lds-ring {
	display: inline-block;
	position: relative;
	width: 40px;
	height: 40px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 32px;
	height: 32px;
	margin: 4px;
	border-radius: 50%;
	animation: lds-ring1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring1 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.lds-ring1 div {
	border: 4px solid #fff;
	border-color: #fff transparent transparent transparent;
}
.lds-ring2 div {
	border: 4px solid #656565;
	border-color: #656565 transparent transparent transparent;
}

ul.dashed {
	list-style-type: none;
}
ul.dashed > li:before {
	content: "-";
	margin-right: 5px;
}

.members-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

body.menu-open {
	overflow-y: hidden;
}

.html-text > ul {
	@apply ml-12;
}

.html-text > ul > li:before {
	content: "-";
	margin-right: 5px;
}

.saf-line-clamp > p,
.saf-line-clamp > ul > li,
.saf-line-clamp > ol > li,
.saf-line-clamp > blockquote {
	display: inline;
}
